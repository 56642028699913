.input-with-label,
.simple-select {

    margin-top: .65rem!important;

    @media (min-width: 700px) {
        margin-top: 1rem!important;
    }

    @media (min-width: 1400px) {
        margin-top: .5rem!important;
    }
}

.input-with-label {
    position: relative;

    @media (max-width: 1200px) {}

    &>div {
        position: relative;
    }

    label {

        display: block;
        position: absolute;
        transform: translate(14px, -6px) scale(0.75);

        top: -.1rem;
        left: -.2rem;

        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        background-color: #F4F5F7;

        padding: 0;
        padding-left: .18rem;
        padding-right: .29rem;

        // font-size: .7rem;
    }

}
/* Animation */
@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.loading-button {

    &,
    &:hover {
        color: #333;
        background-color: #dcdcdc !important;
    }

    .MuiCircularProgress-root {
        position: relative;
        top: .25rem;
    }
}

.dashContainer {
    position: relative;

    &.is-loading {

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            cursor: not-allowed;
    
            opacity: .3;
    
            z-index: 999;
    
            background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
            background-size: 200% 100%;
            border-radius: 20px;
            color: transparent;

        }

        .dash-card {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(90deg,
                        #e0e0e0 25%,
                        #f0f0f0 50%,
                        #e0e0e0 75%);
                background-size: 200% 100%;
                animation: shimmer 2.2s infinite;
                border-radius: 4px;

            }

        }
    }
}